@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Noto Sans JP', 'Yu Gothic Medium', YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
  @apply text-slate-700;
}

.font-admin {
  font-family: 'Yu Gothic Medium', YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
}

code {
  font-family: Consolas, Monaco, Andale Mono, 'Noto Sans JP', 'Yu Gothic Medium', YuGothic,
    Ubuntu Mono, monospace;
}

#article-top {
  @media (max-width: 1279px) {
    @apply flex flex-col gap-4;
    figure {
      max-height: 40vh;
      overflow: hidden;
    }

    #title {
      @apply p-4 flex flex-col gap-4;
    }

    #author {
      @apply px-0 md:px-8 lg:px-12;
    }
  }

  @media (min-width: 1280px) {
    @apply max-w-screen-2xl mt-8 relative flex justify-between flex-col xl:flex-row m-auto w-11/12 2xl:w-4/5 xl:gap-4;
    padding-bottom: 16rem;

    &:before {
      @apply absolute -translate-x-1/2 shadow-inner -translate-y-1/2 bg-slate-100 rounded -z-10 w-3/5 h-3/5 top-1/2 left-1/2;
      content: '';
      z-index: -1;
    }

    figure {
      @apply w-1/2 rounded shadow-lg text-center bg-white overflow-hidden;
      min-height: 16rem;
      max-height: 40vh;
    }

    #title {
      @apply relative rounded bg-white shadow-lg px-12 2xl:px-16 py-12 flex flex-col gap-4 justify-center w-5/12;
      min-width: 500px;
      bottom: -14rem;
    }
  }
}

#article {
  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: 260px 1fr;
  }
  @media (min-width: 1536px) {
    display: grid;
    grid-template-columns: 300px 768px 300px;
  }
}

#tocbot {
  @apply text-sm;
  @media (min-width: 1280px) {
    > ol {
      @apply border-l-2 border-slate-200 pl-4;

      > li {
        &:not(:first-of-type) {
          @apply pt-5;
        }

        > ol {
          @apply pt-5 pl-4;

          > li {
            &:not(:first-of-type) {
              @apply pt-5;
            }

            a.is-active-link:before {
              left: -34px;
            }
          }
        }
      }
    }

    a {
      @apply text-slate-500 hover:text-blue-600;
      &.is-active-link {
        @apply text-slate-800 relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -18px;
          width: 2px;
          height: 100%;
          background-color: #2563eb;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    @apply max-w-screen-md mx-auto mb-12 mt-4 border-y relative list-none overflow-hidden py-8;

    ol {
      @apply list-none mt-4;
    }

    #toc-title {
      @apply text-2xl px-6 flex flex-col items-center text-slate-800 font-semibold;
    }

    #toc-list {
      @apply py-8 px-6;
    }

    li {
      @apply ml-0 md:ml-4 lg:ml-8 xl:ml-12 py-1 md:py-0.5;

      &.h2 {
        @apply pl-4;
      }

      &.h3 {
        @apply pl-12;
      }
    }
  }
}

#kintone-body,
.like-article {
  h1 {
    @apply text-slate-800 text-2xl sm:text-3xl font-bold text-center mb-12 md:mb-16;
  }

  h2,
  font[size='6'] {
    @apply block text-slate-800 text-2xl font-semibold mb-3 md:mb-6 border-b-2 py-4 border-slate-600;
  }

  h3,
  font[size='4'] {
    @apply text-slate-700 text-xl font-semibold mb-3 md:mb-6 border-l-8 border-slate-800 pl-4;
  }

  p {
    @apply text-slate-700 text-base mb-6 md:mb-8;
  }

  a {
    @apply focus:text-teal-700;
  }

  ul,
  ol {
    @apply list-disc list-inside text-slate-600 text-base mb-6 md:mb-8;
  }

  ol {
    @apply list-decimal;
  }

  img {
    @apply shadow-md rounded-md overflow-hidden;
  }

  pre {
    @apply border-slate-200 border mb-8;

    code {
      @apply p-4;
      font-size: 15px;
      font-family: Consolas, Monaco, Andale Mono, ui-monospace, SFMono-Regular, 'Noto Sans JP',
        'Yu Gothic Medium', YuGothic, Ubuntu Mono, monospace;
    }
  }
}

::-webkit-scrollbar {
  @apply w-1 h-1 md:w-2 md:h-2;
}
::-webkit-scrollbar-thumb {
  @apply bg-slate-400 rounded-sm;
}
::-webkit-scrollbar-track {
  @apply bg-transparent;
}
