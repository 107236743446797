.user-token-hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #383a42;
  background: #fafafa;
}
.user-token-hljs-comment,
.user-token-hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}
.user-token-hljs-doctag,
.user-token-hljs-formula,
.user-token-hljs-keyword {
  color: #a626a4;
}
.user-token-hljs-deletion,
.user-token-hljs-name,
.user-token-hljs-section,
.user-token-hljs-selector-tag,
.user-token-hljs-subst {
  color: #e45649;
}
.user-token-hljs-literal {
  color: #0184bb;
}
.user-token-hljs-addition,
.user-token-hljs-attribute,
.user-token-hljs-meta-string,
.user-token-hljs-regexp,
.user-token-hljs-string {
  color: #50a14f;
}
.user-token-hljs-built_in,
.user-token-hljs-class .hljs-title {
  color: #c18401;
}
.user-token-hljs-attr,
.user-token-hljs-number,
.user-token-hljs-selector-attr,
.user-token-hljs-selector-class,
.user-token-hljs-selector-pseudo,
.user-token-hljs-template-variable,
.user-token-hljs-type,
.user-token-hljs-variable {
  color: #986801;
}
.user-token-hljs-bullet,
.user-token-hljs-link,
.user-token-hljs-meta,
.user-token-hljs-selector-id,
.user-token-hljs-symbol,
.user-token-hljs-title {
  color: #4078f2;
}
.user-token-hljs-emphasis {
  font-style: italic;
}
.user-token-hljs-strong {
  font-weight: 700;
}
.user-token-hljs-link {
  text-decoration: underline;
}
